<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <!-- <div v-if="type == 'add'" class="small_title">
      <templateDivider title="省级信息"></templateDivider>
    </div> -->

    <div v-if="type == 'add'" class="box_content">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <templateDivider title="支付信息"></templateDivider>

    <div class="small_title box_content">
      <div>支付情况</div>
      <el-button v-if="type == 'add'" size="mini" type="primary" plain icon="el-icon-plus" @click="addCols"
        >增加</el-button
      >
    </div>

    <div class="box_content">
      <el-table v-loading="loading" ref="table" :data="listData" style="width:100%;" height="500px">
        <el-table-column fixed type="index" label="序号" width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="countyId" width="140" label="行政区域">
          <template slot-scope="scope">
            <el-cascader  :append-to-body="false"  
              v-model="scope.row.countyId"
              size="mini"
              :options="levalRegion"
              :props="treeProps"
              :disabled="type == 'view' || type == 'charts'"
              @change="handleChange($event, scope.$index)"
              :show-all-levels="false"
              placeholder="请选择"
            >
            </el-cascader>
          </template>
        </el-table-column>

        <el-table-column prop="documentNumber" show-overflow-tooltip align="center" label="文号">
          <template slot-scope="scope">
            {{ scope.row.documentNumber ? scope.row.documentNumber : '选择行政区域回显' }}
          </template>
        </el-table-column>

        <el-table-column prop="documentName" show-overflow-tooltip align="center" label="文件名称">
          <template slot-scope="scope">
            {{ scope.row.documentName ? scope.row.documentName : '选择行政区域回显' }}
          </template>
        </el-table-column>
        <el-table-column prop="county" align="center" label="预算">
          <template slot-scope="scope">
            {{ scope.row.county ? scope.row.county : '选择行政区域回显' }}
          </template>
        </el-table-column>

        <el-table-column align="center" :label="title1">
          <el-table-column width="160" align="center" prop="yearlyTotalPayments" label="累计支付总额">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.yearlyTotalPayments"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
                oninput="
                    this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                  .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                  .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                  .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                "
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" prop="yearlyBookBalance" label="账面结存资金">
            <template slot-scope="scope">
              <el-input
                size="mini"
                type="number"
                v-model="scope.row.yearlyBookBalance"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column align="center" :label="title2">
          <el-table-column width="160" prop="nextYearQ1TotalPayments" align="center" label="累计支付总额">
            <template slot-scope="scope">
              <el-input
                size="mini"
                type="number"
                v-model="scope.row.nextYearQ1TotalPayments"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column width="160" prop="nextYearQ1BookBalance" align="center" label="账面结存资金">
            <template slot-scope="scope">
              <el-input
                size="mini"
                type="number"
                v-model="scope.row.nextYearQ1BookBalance"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column width="160" prop="nextYearQ1ReclaimedByFinance" align="center" label="被财政收回资金">
            <template slot-scope="scope">
              <el-input
                size="mini"
                type="number"
                v-model="scope.row.nextYearQ1ReclaimedByFinance"
                :disabled="type == 'view' || type == 'charts'"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column v-if="type == 'add' || type == 'edit'" align="center" width="150" prop="name" label="操作">
          <template slot-scope="scope">
            <el-link type="danger" @click="removeCol(scope.$index, scope.row)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'

import {
  _BudgetExpenditureAdd,
  _BudgetExpenditureInfo,
  _BudgetExpenditureEdit,
  _BudgetExpenditurePolicyPaperSelect
} from '@/api/modular/postImmigrationService/monitorAndTheUse/useOfFunds.js'

export default {
  data() {
    return {
      type: '',
      loading: false,
      row: {},
      title: '',
      input: '',
      listData: [],
      options: [],
      title1: '',
      title2: '',
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }],
        isPowerCounty: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      newarr: ['否', '是'],
      options: [
        { label: '是', value: true },
        { label: '否', value: false }
      ]
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },

  created() {
    this.getLevelRegion()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    handleChange1(value, index) {
      console.log(value, '=======handleChange1=======')
      // this.listData[index].countyId = obj.id
      // this.listData[index].county = obj.name
    },
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        id: 1
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    calculateTheTotal(name) {
      let sum = 0
      for (let i = 0; i < this.listData.length; i++) {
        let ele = this.listData[i]
        sum += Number(ele[name])
      }
      return sum
    },
    refreshYear(year) {
      this.searchObj.year = year
      this.title1 = `截至${this.searchObj.year}年12月31日资金支付情况（万元）`
      this.title2 = `截至${this.searchObj.year + 1}年3月31日资金支付情况（万元）`
    },
    add(type) {
      this.type = type
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      this.title1 = `截至${this.row.year}年12月31日资金支付情况（万元）`
      this.title2 = `截至${this.row.year + 1}年3月31日资金支付情况（万元）`
      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
    },
    btnClose() {
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: 0, //县（市区）
            county: '', //县（市区）
            countyPids: '', //pids
            orderNo: 0,
            isPowerCounty: null,
            policyPapersId: null,
            // cityPolicyPapersId: 0,
            budgetAmount: 0,
            yearlyTotalPayments: 0,
            yearlyBookBalance: 0,
            nextYearQ1TotalPayments: 0,
            nextYearQ1BookBalance: 0,
            nextYearQ1ReclaimedByFinance: 0
          }
          this.listData.splice(this.listData.length, 0, obj)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },

    submitForm() {
      let listData = this.listData.map(ele => {
        return {
          ...ele,
          budgetAmount: Number(ele.budgetAmount),
          stabilityPlanFalse: ele.stabilityPlanTrue == 0 ? 1 : 0
        }
      })

      // 没有预算不能支付
      let flag = this.listData.every(ele => ele.countyId)

      console.log(listData, '===submitForm===')
      switch (this.type) {
        case 'add':
          _BudgetExpenditureAdd(listData).then(res => {
            if (res.code == 200) {
              this.$message.success('添加成功')
              this.btnClose()
            } else {
              this.$message.error(res.message)
            }
          })
          break
        case 'edit':
          let ele = listData[0]
          let params = {
            year: ele.year,
            countyId: ele.year,
            county: ele.county,
            countyPids: ele.countyPids,
            orderNo: ele.orderNo,
            isPowerCounty: ele.isPowerCounty,
            policyPapersId: ele.policyPapersId,
            cityPolicyPapersId: ele.cityPolicyPapersId,
            budgetAmount: ele.budgetAmount,
            yearlyTotalPayments: ele.yearlyTotalPayments,
            yearlyBookBalance: ele.yearlyBookBalance,
            nextYearQ1TotalPayments: ele.nextYearQ1TotalPayments,
            nextYearQ1BookBalance: ele.nextYearQ1BookBalance,
            nextYearQ1ReclaimedByFinance: ele.nextYearQ1ReclaimedByFinance,
            id: ele.id
          }
          _BudgetExpenditureEdit(params).then(res => {
            if (res.code == 200) {
              this.type = 'view'
              this.$message.success('修改成功')
              this.getBasicInfo()
            } else {
              this.$message.error(res.message)
            }
          })
          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }

      _BudgetExpenditureInfo(params).then(res => {
        if (res.code == 200) {
          let arr = [res.data]
          this.listData = arr
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';

::v-deep tbody tr.el-table__row:nth-child(1) {
  height: 49px;
  border: 0 !important;
  background-color: #fff !important;
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 1;

  td {
    border-top: 0 !important;
    border-bottom: 1px solid #f5f7fa !important;
  }

  td:first-child {
    border-left: 0 !important;
  }

  td:last-child {
    border-right: 0 !important;
  }
}
</style>
